<template>
  <div class="whole-sale">
    <ControlNavbar/>
    <MyBanner :banner="wholeSaleObject.banner" v-if="wholeSaleObject"/>
    <page-header :title="wholeSaleObject.label" v-if="wholeSaleObject"/>
    <br/>

    <div class="container" v-if="wholeSaleObject">
      <div class="" style="text-align: end;">
        <h4>
          {{ locale.total }} {{$session.get("currency") }}{{total | numeral($session.get("currencyFormat").replace("#", 0))}}
        </h4>
        <hr/>
      </div>
      <form
          @change="
          (e) => {
            e.preventDefault();
          }
        "
          onsubmit="return false"
          @submit="
          (e) => {
            e.preventDefault();
            e.returnValue = false;
          }
        "
      >
        <div
            class=" items"
            v-for="(item, index) in wholeSaleObject.data.categories"
            :key="index"
        >
          <div class="row">
            <h5 class="col-12">
              <img
                  onerror="this.style.display='none'"
                  v-if="item.iconUrl"
                  :src="item.iconUrl"
                  width="70px"
                  height="70px"
              />{{ item.label }}
            </h5>
          </div>
          <div
              class="d-flex justify-content-between subItems"
              v-for="subItem in item.items"
              :id="subItem.id"
              :key="subItem.id"
          >
            <div class="col-4">
              <p>{{ subItem.label }}</p>
            </div>
            <div class="col-4">
              <p>{{ $session.get("currency") }} {{ subItem.price }}</p>
            </div>
            <div class="col-4">
              <p>
                <button
                    type="button"
                    @click="
                    (e) => {
                      e.preventDefault();
                      decreaseQuantity(
                        $refs[`${subItem.id}_counter`],
                        subItem,
                        item
                      );
                    }
                  "
                >
                  -
                </button>
                <input
                    @change="
                    (e) => {
                      changeQuantity(
                        e,
                        $refs[`${subItem.id}_counter`],
                        subItem,
                        item
                      );
                    }
                  "
                    :ref="`${subItem.id}_counter`"
                    type="number"
                    :min="subItem.quantity.min"
                    :max="subItem.quantity.max"
                />
                <button
                    type="button"
                    @click="
                    (e) => {
                      e.preventDefault();
                      addQuantity(
                        $refs[`${subItem.id}_counter`],
                        subItem,
                        item
                      );
                    }
                  "
                >
                  +
                </button>
              </p>
            </div>
            <hr/>
          </div>
        </div>
      </form>
      <button @click="submit" type="button" class="whish-button">
        {{ wholeSaleObject.data.button }}
      </button>
    </div>
    <a-modal
        v-if="this.cart"
        v-model="checkoutVisible"
        :title="locale.checkout"
        :footer="null"
    >
      <p v-for="item in cart" :key="item.id">
        <b>{{ item.parentName }}</b> {{ item.itemLabel }} x {{ item.count }}
      </p>
      <div style="text-align: end;">
        <h4>
          {{ locale.total }} {{
            $session.get("currency")
          }}{{
            (total + (deliveryPrice || 0))
                | numeral($session.get("currencyFormat").replace("#", 0))
          }}
        </h4>
        <hr/>
      </div>
      <a-form
          :form="form"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 14 }"
          @submit="handleSubmit"
      >
        <a-form-item>
          <a-radio-group
              v-decorator="[
              'paymentOptionId',
              {
                rules: [
                  {
                    required: true,
                    message: locale.emptyFieldsWarning,
                  },
                ],
              },
            ]"
              button-style="solid"
              @change="onPaymentTypeChange"
              style="margin-bottom: 3%; margin-right: 9%"
          >
            <a-radio-button
                v-for="(option, index) in wholeSaleObject.data.options"
                :key="index"
                :value="option.id"
            >
              {{ option.label }}
            </a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item :label="locale.address_1">
          <a-input
              :placeholder="locale.address_1"
              style="width: 100%"
              v-decorator="[
              'address1',
              {
                rules: [
                  {
                    required: true,
                    message: locale.emptyFieldsWarning,
                  },
                ],
              },
            ]"
          />
        </a-form-item>
        <a-form-item :label="locale.address_2">
          <a-input
              :placeholder="locale.address_2"
              style="width: 100%"
              v-decorator="[
              'address2',
              {
                rules: [
                  {
                    required: false,
                  },
                ],
              },
            ]"
          />
        </a-form-item>
        <a-form-item :label="locale.full_name">
          <a-input
              :placeholder="locale.full_name"
              style="width: 100%"
              v-decorator="[
              'name',
              {
                rules: [
                  {
                    required: true,
                    message: locale.emptyFieldsWarning,
                  },
                ],
              },
            ]"
          />
        </a-form-item>
        <a-form-item :label="locale.phone_number">
          <a-input
              :placeholder="locale.phone_number"
              style="width: 100%"
              v-decorator="[
              'phone',
              {
                rules: [
                  {
                    required: true,
                    message: locale.emptyFieldsWarning,
                  },
                ],
              },
            ]"
          />
        </a-form-item>
        <a-form-item
            :wrapper-col="{ span: 12, offset: 5 }"
            v-if="orderButtonText"
        >
          <a-button type="danger" html-type="submit">
            {{ orderButtonText }}
          </a-button>
        </a-form-item>
      </a-form>
    </a-modal>

    <a-modal
        v-model="paymentOptionsVisible"
        :title="locale.paymentoptions"
        @ok="paymentOptionsVisible = !paymentOptionsVisible"
        :footer="null"
        v-if="selectedPrices"
    >
      <div v-if="showPaymentOptions">
        <div
            v-for="option in $session.get('paymentOptions')"
            :key="option.id"
            @click="selectPaymentOption(option)"
        >
          <div
              class="
              d-flex
              justify-content-between
              card_custom
              pointer
              align-items-center
            "
              v-if="selectedPrices[`${option.id}`]"
          >
            <p>{{ option.label }}</p>
            <p>
              {{ selectedPrices[`${option.id}`] }}
            </p>
          </div>
        </div>
      </div>

      <PaymentCC
          v-if="paymentOption == 'creditcard'"
          @options="
          ($event) => {
            this.showPaymentOptions = $event;
          }
        "
          :apiBody="apiBody"
          @handleOldWholeSale="handleOldWholeSale"
      />
    </a-modal>

    <a-modal
        v-if="this.successItems"
        v-model="successItemsVisible"
        :title="locale.success"
        :footer="null"
    >
      <div
          v-for="trx in successItems"
          :key="trx.item.id"
          style="border-bottom: 1px solid grey;"
          class="d-flex justify-content-between mb-4"
      >
        <p>
          <b>{{ trx.item.parentName }}</b> {{ trx.item.itemLabel }} x
          {{ trx.item.count }}
        </p>
        <p>
          {{ trx.dialogue.title }} <br/>
          {{ trx.dialogue.message }}
        </p>
        <hr/>
      </div>
    </a-modal>

    <loading :loading="loading"/>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import "bootstrap";
import ControlNavbar from "@/components/ControlNavbar";
import {API_ENDPOINTS} from "../scripts/request";
import PaymentCC from "@/components/PaymentCC";
import {LocaleArabic, LocaleEnglish} from "../scripts/Locale";
import firebase from "firebase";

export default {
  name: "WholeSale",
  components: {ControlNavbar, PaymentCC},
  data() {
    return {
      formLayout: "horizontal",
      form: this.$form.createForm(this, {name: "coordinated"}),
      checkoutVisible: false,
      locale: null,
      loading: false,
      apiEndpoints: API_ENDPOINTS,
      wholeSaleObject: null,
      cart: [],
      total: 0,
      orderButtonText: null,
      deliveryPrice: null,

      // cc payment prices of selected phone
      selectedPrices: null,
      ccbodytoSubmit: null,
      // credit card payment
      paymentOptionsVisible: false,
      paymentOption: null,
      apiBody: null,
      showPaymentOptions: true,

      successItems: [], // used to store the response after submit and success to show each item if it has succeeded or not.
      successItemsVisible: false, // used to show and hode success items modal
    };
  },
  beforeMount() {
    if (this.$route.params.old) {
      let values = this.$route.params.old;
      this.handleOldWholeSale(values);
    }
    if (this.$route.params.object) {
      // good
      this.wholeSaleObject = this.$route.params.object;
      this.$session.set("backup", this.$route.params.object);
    } else {
      // undefined take from cache
      this.wholeSaleObject = this.$session.get("backup");
    }
    console.log("wholesale obj", this.wholeSaleObject);
    this.checkLocal();
  },
  methods: {
    handleOldWholeSale(values) {
      this.getAxios()
          .post(this.apiEndpoints.wholeSaleCheckout, values)
          .then((result) => {
            if (this.checkApiRes(result, values, this.apiEndpoints.wholeSaleEndpoint, "post")) {
              console.log(result);
            }
          });
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          values.items = this.cart;
          values.longitude = 0;
          values.latitude = 0;
          console.log("Received values of form: ", values);

          if (values.paymentOptionId == "creditcard") {
            values.type = this.wholeSaleObject.type;
            console.log(values);
            this.ccbodytoSubmit = values;
            this.paymentOptionsVisible = true;
          } else {
            this.setLoader(true);
            this.checkoutVisible = false;
            this.getAxios()
                .post(this.apiEndpoints.wholeSaleCheckout, values)
                .then((result) => {
                  this.setLoader(false);
                  if (this.checkApiRes(result, values, this.apiEndpoints.wholeSaleEndpoint, "post")) {
                    // ok
                    for (let i = 0; i < values.items.length; i++) {
                      let item = {
                        dialogue: result.data.data[values.items[i].id].dialog,
                        item: values.items[i],
                      };
                      this.successItems.push(item);
                    }
                    this.successItemsVisible = true;
                  }
                });
          }
        }
      });
    },
    onPaymentTypeChange(e) {
      let val = e.target.value;
      this.wholeSaleObject.data.options.forEach((option) => {
        if (option.id == val) {
          this.orderButtonText = option.button;
          this.deliveryPrice = option.price;
        }
      });
    },
    logout() {
      this.getAxios()
          .post(`${this.$session.get("baseURI")}account/session/deactivate`, {
            sessionId: this.$session.get("sessionId"),
          })
          .then((result) => {
            if (this.checkApiResponse(result)) {
              this.$session.clear();
              firebase
                  .auth()
                  .signOut()
                  .then(() => {
                    this.$router.replace("/login").catch(() => {
                    });
                    console.log("Sign-out successful");

                    // Sign-out successful.
                  })
                  .catch((error) => {
                    console.log(error);
                    // An error happened.
                  });
            } else {
              this.$session.clear();
              firebase
                  .auth()
                  .signOut()
                  .then(() => {
                    this.$router.replace("/login").catch(() => {
                    });
                    console.log("Sign-out successful");

                    // Sign-out successful.
                  })
                  .catch((error) => {
                    console.log(error);
                    // An error happened.
                  });
            }
          });
    },
    changeQuantity(e, input, item, parentItem) {
      e.preventDefault();
      if (e.target.value > item.quantity.max) {
        input.value = item.quantity.max;
        e.target.value = item.quantity.max;
      } else if (e.target.value < item.quantity.min) {
        input.value = item.quantity.min;
        e.target.value = item.quantity.min;
      }
      input.value = Number(e.target.value);
      let flag = true;
      for (let i = 0; i < this.cart.length; i++) {
        let elt = this.cart[i];
        if (elt.id == item.id && input.value <= 0) {
          flag = false;
          this.cart.splice(i, 1);
        } else if (elt.id == item.id) {
          flag = false;
          this.cart[i] = {
            parentName: parentItem.label,
            itemLabel: item.label,
            id: item.id,
            count: input.value,
            costPerUnit: item.price,
            price: 0,
          };
        }
      }
      if (flag) {
        this.cart.push({
          parentName: parentItem.label,
          itemLabel: item.label,
          id: item.id,
          count: input.value,
          costPerUnit: item.price,
          price: 0,
        });
      }
      this.getTotal();
    },
    submit() {
      if (this.cart.length > 0) {
        this.checkoutVisible = true;
      }
    },
    addQuantity(input, item, parentItem) {
      if (input.value > item.quantity.max) {
        input.value = item.quantity.max.toString();
        console.log(input, item.quantity.max);

        return;
      } else if (input.value < item.quantity.min) {
        input.value = item.quantity.min;
        return;
      }

      input = input[0];
      if (item.quantity.max > Number(input.value)) {
        input.value = Number(input.value) + 1;
        let flag = true; // used to know if it's true after forloop thus we should push else we edited it in the for loop
        for (let i = 0; i < this.cart.length; i++) {
          const element = this.cart[i];
          if (element.id == item.id) {
            flag = false;
            this.cart[i] = {
              parentName: parentItem.label,
              itemLabel: item.label,
              id: item.id,
              count:
                  Number(input.value) < Number(item.quantity.max)
                      ? Number(input.value)
                      : item.quantity.max,
              costPerUnit: item.price,
              price: 0,
            };
          }
        }
        if (flag) {
          this.cart.push({
            parentName: parentItem.label,
            id: item.id,
            itemLabel: item.label,
            count:
                Number(input.value) < Number(item.quantity.max)
                    ? Number(input.value)
                    : item.quantity.max,
            costPerUnit: item.price,
            price: 0,
          });
        }
      }
      this.getTotal();
    },
    decreaseQuantity(input, item, parentItem) {
      if (input.value > item.quantity.max) {
        input.value = item.quantity.max;
        return;
      } else if (input.value < item.quantity.min) {
        input.value = item.quantity.min;
        return;
      }

      input = input[0];
      if (item.quantity.min < Number(input.value)) {
        input.value = Number(input.value) - 1;

        for (let i = 0; i < this.cart.length; i++) {
          const element = this.cart[i];
          if (element.id == item.id) {
            if (Number(input.value) == 0) {
              this.cart.splice(i, 1);
            } else {
              this.cart[i] = {
                itemLabel: item.label,
                parentName: parentItem.label,
                id: item.id,
                count:
                    Number(input.value) > Number(item.quantity.min)
                        ? Number(input.value)
                        : Number(item.quantity.min),
                costPerUnit: item.price,
                price: 0,
              };
            }
          }
        }
      }
      this.getTotal();
    },
    info(title, message) {
      const h = this.$createElement;
      let self = this;
      this.$info({
        title: title,
        content: h("div", {}, [h("p", message)]),
        okText: this.locale.ok,
        onOk() {
          self.$router.push({
            path: "Home",
          });
        },
      });
    },
    checkLocal() {
      if (this.$session.get("lang") && this.$session.get("lang") == "ar") {
        this.locale = LocaleArabic;
      } else {
        this.locale = LocaleEnglish;
      }
    },
    checkApiResponse(result) {
      try {
        if (result.headers.sessioncounter) {
          this.$session.set("sessionCounter", result.headers.sessioncounter);
        }
        if (!result.data.status) {
          // error
          this.$message.error(result.data.dialog.message);
          console.error(`error in (${result.config.url})`, result);
          if (result.data.code === "auth.session_expired") {
            // log user out
            this.logout();
          }
        } else {
          return true;
        }
        return false;
      } catch (e) {
        return false;
      }
    },
    getTotal() {
      console.log("total");
      let total = 0;
      for (let i = 0; i < this.cart.length; i++) {
        let elt_i = this.cart[i];
        total += elt_i.count * elt_i.costPerUnit;
      }
      this.total = total;
    },
    getAxios() {
      return axios.create({
        headers: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Origin": "*",
          language: this.$session.get("lang") || "en",
          itelVersion: "1000",
          uid: "uidtest1.3",
          brand: "Web",
          model: "Web",
          osVersion: "unix",
          operatingSystem: "4",
          sessionId: `${this.$session.get("sessionId")}`,
          token: `${this.$session.get("token")}`,
          deviceId: `${this.$session.get("deviceid")}`,
          sessionCounter: `${this.$session.get("sessionCounter") || 0}`,
          counter: `${this.$session.get("counter")}`,
        },
      });
    },
    setLoader(show) {
      this.loading = show;
    },
  },
};
</script>

<style lang="scss" scoped>
.whish-button {
  background-color: white;
  border: 2px solid #e62946;
  color: #e62946;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 30px;
}

.whish-button:hover {
  background-color: #e62946;
  border: 2px solid #e62946;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 30px;
}

.items {
  background: lightgray;
  border-radius: 25px;
  padding: 25px;
  margin-bottom: 10px;

  h5 {
    text-align: start;
    font-weight: 600;
  }

  .subItems {
    border-bottom: 1px solid grey;

    input {
      border: 0px;
      background: darkgray;
      border-radius: 5px;
      padding: 2px;
      margin-right: 2px;
      margin-left: 2px;
    }

    button {
      width: 30px;
      margin-right: 5px;
      margin-left: 5px;
      background: none;
      border: 1px solid grey;
      background: grey;
      color: white;
      border-radius: 5px;
      padding: 1px 8px 1px 8px;
    }

    p {
      margin-top: 10px;
    }

    text-align: start;
  }
}
</style>
